import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton, Seo, Text } from 'components';
import { useRouter } from 'apis/history';
import { useQuizData } from 'utils/hooks';
import ResultsProgress from 'components/ResultsProgress';
import Logo from 'assets/icons/logo.svg';
import third from 'assets/videos/testimonials/lc-1.mp4';
import forth from 'assets/videos/testimonials/lc-2.mp4';
import second from 'assets/videos/testimonials/lc-3.mp4';
import first from 'assets/videos/testimonials/lc-4.mp4';
import Verified from 'assets/icons/isverified.svg';

const ResultsReviewsVideos: FC = () => {
  const [loading, setLoading] = useState(false);
  const data = useQuizData('resultsReviewsVideos');
  const { goToCheckout } = useRouter();
  const VideoMap = [first, second, third, forth];
  //   const nextStep = () => {
  //     setLoading(true);
  //     goToCheckout({ discount: false });
  //   };
  const handleClick = () => {
    setLoading(true);
    goToCheckout({ discount: false });
  };
  return (
    <>
      <Container>
        <Inner>
          <LogoStyled />
          <ResultsProgress
            currentStep={data?.currentStep}
            totalSteps={data?.totalSteps}
          />
          <Card>
            <Title dangerouslySetInnerHTML={{ __html: data?.title }} />

            <ListContainer>
              {data?.list.map((item, index) => (
                <ListItem key={index}>
                  <ImgContainer>
                    <StyledVideo
                      crossOrigin="anonymous"
                      aria-label="Video"
                      autoPlay
                      loop
                      muted
                      playsInline
                      preload="metadata"
                      x-webkit-airplay="allow"
                      width="100%"
                      height="100%"
                    >
                      <source src={VideoMap[index]} type="video/mp4" />
                    </StyledVideo>
                  </ImgContainer>
                  <TextContainer>
                    <Top>
                      <ListText>{item.review}</ListText>
                    </Top>
                    <BottomContainer>
                      <Name>{item.name}</Name>
                      <Verified />
                    </BottomContainer>
                  </TextContainer>
                </ListItem>
              ))}
            </ListContainer>
            <StyledButton onClick={handleClick} loading={loading}>
              {data?.buttonText}
            </StyledButton>
          </Card>
        </Inner>
      </Container>
    </>
  );
};

export default ResultsReviewsVideos;

const StyledVideo = styled.video`
  object-fit: cover;
  width: 5rem;
  border-radius: 0.5rem;
`;

const Container = styled.section`
  background: #ffe7de;
  padding: 2rem 1rem;
  @media ${tablet} {
    padding: 0 1rem 2rem;
  }
  min-height: 100vh;
`;

const Inner = styled.section`
  max-width: 31.25rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoStyled = styled(Logo)`
  margin: 1.25rem auto;
`;

const Card = styled.section`
  display: flex;
  width: 100%;
  padding: 1.5rem 1rem;
  flex-direction: column;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 4.935px 16px 0px rgba(0, 0, 0, 0.08);
  margin-top: 1.5rem;
  @media ${tablet} {
    margin-top: 2rem;
  }
`;

const Title = styled.h1`
  color: #28293d;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.063rem;
  margin-bottom: 1.5rem;
  span {
    color: #ff875a;
  }
  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const StyledButton = styled(PrimaryButton)`
  border-radius: 6.25rem;
  background: #804da1;
  box-shadow: 0px 0px 1px 0px rgba(40, 41, 61, 0.04),
    0px 2px 4px 0px rgba(96, 97, 112, 0.16);
  width: 100%;
`;

const TextContainer = styled.section`
  width: 100%;
`;

const ListContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  margin-bottom: 1.5rem;
`;

const ListItem = styled.section`
  display: flex;
  gap: 0.75rem;
  overflow: hidden;
  border-radius: 0.5rem;
  height: 6.875rem;
  width: 100%;
`;

const Top = styled.section`
  margin-bottom: 0.5rem;
  width: 100%;
`;

const ListText = styled(Text)`
  color: #28293d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const Name = styled(Text)`
  color: #28293d;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const BottomContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ImgContainer = styled.section`
  width: 5rem;
  border-radius: 0.5rem;
`;
